import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { productsApi } from "./api";

const useProductsLightWithDeleted = createPaginatedApiQuery(
  productsApi.getProductsLightWithDeleted,
);

export const productsActions = {
  useProductsLightWithDeleted,
};
