import { Pagination } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { ProductLightWithDeleted } from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { productsKeys } from "api/keys";

const getProductsLightWithDeleted = (
  search: string = "",
): ApiFetcher<Pagination<ProductLightWithDeleted>> => ({
  key: productsKeys.productsWithDeleted(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/light-with-deleted" + search,
    }),
});

export const productsApi = {
  getProductsLightWithDeleted,
};
